@font-face {
  font-family: ProximaNova;
  src: url(../assets/fonts/proxima-nova/proxima-nova-thin.woff) format('woff'), url(../assets/fonts/proxima-nova/proxima-nova-thin.woff2) format('woff2'),
    url(../assets/fonts/proxima-nova/proxima-nova-thin.ttf) format('truetype');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: ProximaNova;
  src: url(../assets/fonts/proxima-nova/proxima-nova-light.woff) format('woff'), url(../assets/fonts/proxima-nova/proxima-nova-light.woff2) format('woff2'),
    url(../assets/fonts/proxima-nova/proxima-nova-light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: ProximaNova;
  src: url(../assets/fonts/proxima-nova/proxima-nova-lightIt.woff) format('woff'), url(../assets/fonts/proxima-nova/proxima-nova-lightIt.woff2) format('woff2'),
    url(../assets/fonts/proxima-nova/proxima-nova-lightIt.ttf) format('truetype');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: ProximaNova;
  src: url(../assets/fonts/proxima-nova/proxima-nova-regular.woff) format('woff'), url(../assets/fonts/proxima-nova/proxima-nova-regular.woff2) format('woff2'),
    url(../assets/fonts/proxima-nova/proxima-nova-regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: ProximaNova;
  src: url(../assets/fonts/proxima-nova/proxima-nova-regularIt.woff) format('woff'),
    url(../assets/fonts/proxima-nova/proxima-nova-regularIt.woff2) format('woff2'),
    url(../assets/fonts/proxima-nova/proxima-nova-regularIt.ttf) format('truetype');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: ProximaNova;
  src: url(../assets/fonts/proxima-nova/proxima-nova-semibold.woff) format('woff'),
    url(../assets/fonts/proxima-nova/proxima-nova-semibold.woff2) format('woff2'),
    url(../assets/fonts/proxima-nova/proxima-nova-semibold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: ProximaNova;
  src: url(../assets/fonts/proxima-nova/proxima-nova-semiboldIt.woff) format('woff'),
    url(../assets/fonts/proxima-nova/proxima-nova-semiboldIt.woff2) format('woff2'),
    url(../assets/fonts/proxima-nova/proxima-nova-semiboldIt.ttf) format('truetype');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: ProximaNova;
  src: url(../assets/fonts/proxima-nova/proxima-nova-bold.woff) format('woff'), url(../assets/fonts/proxima-nova/proxima-nova-bold.woff2) format('woff2'),
    url(../assets/fonts/proxima-nova/proxima-nova-bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: ProximaNova;
  src: url(../assets/fonts/proxima-nova/proxima-nova-boldIt.woff) format('woff'), url(../assets/fonts/proxima-nova/proxima-nova-boldIt.woff2) format('woff2'),
    url(../assets/fonts/proxima-nova/proxima-nova-boldIt.ttf) format('truetype');
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: ProximaNova;
  src: url(../assets/fonts/proxima-nova/proxima-nova-xbold.woff) format('woff'), url(../assets/fonts/proxima-nova/proxima-nova-xbold.woff2) format('woff2'),
    url(../assets/fonts/proxima-nova/proxima-nova-xbold.ttf) format('truetype');
  font-style: normal;
  font-weight: 800;
}
